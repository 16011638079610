import Cookies from './cookie'
const TokenKey = `Token` // 登录授权
const FId = `FId` // 邀请人id
const URL = 'URL' // 项目落地地址
const Lang = 'Lang' //语言
const inviteCode = 'inviteCode' //邀请人
const chainId = 'chainId'
const ChainName = 'chainName'
const Psw = 'Psw'
const showNews = 'showNews' //


export function getToken() {
  return sessionStorage.getItem(TokenKey)
}
export function setToken(token) {
  return sessionStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return sessionStorage.removeItem(TokenKey)
}

export function getPsw(){
	return Cookies.getItem(Psw)
}

export function setPsw(key){
	return Cookies.setItem(Psw, key, 86400)
}

export function setFId(key) {
  return Cookies.setItem(FId, key, { expires: 1 })
}
export function getFId() {
  return Cookies.getItem(FId)
}

export function setURL(key) {
  return Cookies.setItem(URL, key, 86400)
}
export function getURL() {
  return Cookies.getItem(URL)
}


export function getLang(){
	return localStorage.getItem(Lang)
}

export function setLang(key){
	return localStorage.setItem(Lang,key)
}

export function getInviteCode(){
	return sessionStorage.getItem(inviteCode)
}

export function setInviteCode(key){
	return sessionStorage.setItem(inviteCode,key)
}

export function getChainId(){
	return sessionStorage.getItem(chainId)
}

export function setChainId(key){
	return sessionStorage.setItem(chainId,key)
}

export function getChainName(){
	return sessionStorage.getItem(ChainName)
}

export function setChainName(key){
	return sessionStorage.setItem(ChainName,key)
}


export function getshowNews(){
	return localStorage.getItem(showNews)
}

export function setshowNews(key){
	return localStorage.setItem(showNews,key)
}


