import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import { store } from './store'
import './App.css'
import './css/style.less'
import 'lib-flexible'  
import 'vue3-emoji-picker/css'

import { setToastDefaultOptions } from 'vant'; 
setToastDefaultOptions({ duration: 2000 });
import vconsole from 'vconsole'
// 测试环境开启控制台
// if (import.meta.env.MODE === 'test') {
//   // new vconsole()
// }


import InstallPlugins from './plugins'

const Instance = createApp(App)
// 挂载插件
InstallPlugins(Instance)

Instance.use(store).use(router).mount('#app')
