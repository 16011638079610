<template>
  <div id="App">
    <router-view v-slot="{Component}">
      <Transition name="fade-transform" mode="out-in">
        <component :is="Component"></component>	
      </Transition>
    </router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  mounted() {
    
  },
}
</script>
<style lang="less">
.login-wrap{
    .van-cell{
        background: #221F1C!important;
    }
    .van-field__control{
        color:#FBD3A3;
    }
    .van-button{
        background: linear-gradient(90deg, #FFEBD6,#E3AC7A);
        border: 0;
        line-height:48px;
        height: 48px;
    }
}
.van-button--plain{
  background: none!important;
}
.form-wrap{
  .van-button{
        background: linear-gradient(90deg, #FFEBD6,#E3AC7A);
        border: 0;
        line-height:48px;
        height: 48px;
    }
    .van-cell{
      padding-left: 0;
      padding-top: 15px;
      padding-bottom: 15px;
    }
}
.van-dialog{
  background: #131313!important;
  .van-dialog__header{
    color:#FBD3A3;
  }
  .van-dialog__cancel{
    color:#88745B;
  }
  .van-dialog__confirm{
    color:#FBD3A3;
  }
  .van-dialog__cancel,.van-dialog__confirm{
    background:#131313;
  }
}
[class*=van-hairline]:after{
  border:1px solid rgab(255,255,255,.1)!important;
}
.van-field__control:disabled{
  color:#FBD3A3!important;
  -webkit-text-fill-color:#FBD3A3!important;
}
.van-field__body  input::-webkit-input-placeholder{
  color:#8F7960;
}
.van-field__body  input::-moz-input-placeholder{
  color:#8F7960;
}
.van-field__body  input::-ms-input-placeholder{
  color:#8F7960;
}
.van-field__body  input:-ms-input-placeholder{
  color:#8F7960;
}
.van-field__body input::placeholder{
  color:#8F7960!important;
}
.van-field__body textarea::placeholder{
  color:#8F7960!important;
}
textarea::placeholder{
  color:#8F7960!important;
}
.van-cell:after{
  border-bottom:1px solid rgba(255,255,255,.1)!important;
}
.van-cell__right-icon{
  color:#8F7960!important;
}
.van-field__label{
color:#FBD3A3!important;
}
.van-field__body input{
color:#FBD3A3!important;

}
.van-picker__confirm{
color:#FBD3A3!important;

}

.van-count-down{
font-size: 15px;
color:#53210E;
}
.home-wrap{
  .van-nav-bar{
    background: #131313;
  }
  .van-notice-bar{
    padding:0!important;
  }
} 
.van-nav-bar .van-icon{
  color: #FBD3A3!important;
}
.wobble-active {
  animation: waggle 3s infinite;
}
.van-nav-bar{
  background: #131313!important;
}
.van-theme-dark body {
  .van-nav-bar{
 background:black;
  }
  color: #fff;
  background-color: black;
  .van-grid{
    border-radius: 8px;
    background: #000;
    border: 1px solid #fff;
    overflow: hidden;
  }
  .van-grid-item__content{
    background: #000;
  }
  .van-grid-item__content{
    border:0 solid #fff;
    border-width: 0 1px 1px 0;
  }
  .van-grid-item:nth-last-child(2n+1) .van-grid-item__content{
    border-width:0  0 1px 0;
  }
  .van-grid-item:nth-last-child(1) .van-grid-item__content{
    border-width: 0  0 0 0;
  }
  .van-grid-item:nth-last-child(2) .van-grid-item__content{
    border-width: 0 1px 0 0;
  }
  .van-grid-item__content:after{
    border-width:0;
    border-color:#000;
  }
  .van-popover--dark .van-popover__content{
    background: #222;
  }
  .van-popover--dark .van-popover__arrow{
    color:#222;
  }
  .van-empty__description{
    color:#ddd;
  }
}
.van-grid{
  background: #000;
  .van-grid-item,.van-grid-item__content{
  background: #000;

  }
}
.van-cell{
background: #1c1c1e!important;
}
.tan-box{
		width: 80vw;
		min-height: 26vh;
		padding-top: 20px;
		// background: url("@/assets/imgs/default_bg_pop.png") no-repeat left top / 100% 100%;
		overflow: hidden;
	}

@keyframes waggle {
  0%,
  7% {
    transform: rotate(0);
  }
  15%, 65% {
    transform: rotate(-15deg);
  }
  20%, 70% {
    transform: rotate(10deg);
  }
  25%, 75% {
    transform: rotate(-10deg);
  }
  30%, 80% {
    transform: rotate(6deg);
  }
  35%, 85% {
    transform: rotate(-4deg);
  }
  40%, 57%, 90%,
  100% {
    transform: rotate(0);
  }
}

.title-right{
.stats_icon{
  height: 12px;
}
.more_icon{
  width: 15px;
  height: 15px;
}
}
.form-list{
  .li{
    position: relative;
    line-height: 44px;
  }
  .li::after{
    transform: scaleY(0.8);
    position: absolute;
    bottom: 0;
    left: 0;
    content:'';
    width: 100%;
    height:1px;
    background: linear-gradient(135deg, #F2EEFF 27%, #E3EFFF 41%, #CEF2EB 50%, #EEF0FF 59%, #FFFFFE 69%, #E9EEFF 81%, #CFEAFF 90%, #C8F5DC 100%);
  }
}
.border-fff{
  border:1px solid #fff;
  border-radius: 8px;
}
.form-card{
  width: 92%;
  margin: 0 auto;
  .form{
    border: 1px solid #fff;
    border-radius: 8px;
    padding: 20px;
    .lable{
      width: 33%;
      line-height: 30px;
     text-align: justify;
     text-justify: inter-word; /* 适用于中英文混排 */
     text-align-last: justify;
    }
    .input{
      width:64%;
      border-bottom: 1px solid #fff;
      min-height: 30px;
      .van-cell{
        padding: 0 10px;
        background: #000;
        input{
          height: 30px;
          line-height: 30px;
          color:#fff;
          font-size: 15px;
        }
      }
    }
  }
}

.chat-wrap{
    .van-cell{
        background: #221F1C!important;
        padding: 10px;
    }
    textarea{
            resize: none;
            border: none;
            outline: none;
            padding: 0;
            margin: 0;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-image: none;
            background: #221F1C;
            color:#FBD3A3;
            width: 100%;
            padding:5px 10px;
            line-height: 22px;
            font-size: 14px;
            height: auto;
            border-radius: 5px;
    }
    .v3-emoji-picker .v3-footer{
        display: none!important;
        height: 0!important;
        .v3-foot-left{
            display: none!important;
        }
    }
    .v3-emoji-picker .v3-body .v3-body-inner .v3-group .v3-emojis button{
        font-size: 20px!important;
    }
    .v3-emoji-picker .v3-header .v3-groups {
      display: flex;
      justify-content: space-between;
    }
    .v3-emoji-picker .v3-header .v3-groups .v3-group{
        display: flex;
        justify-content: space-between;
        flex-grow: 0!important;
    }

    .van-switch{
      background: #595959;
    }
    .van-switch__node{
      background: #dda766;
    }
}

.pay-wrap{
    .van-collapse-item__content{
        background: #221F1C;
    }
    .van-cell{
       background: #221F1C!important; 
    }
    .van-cell__title,.van-cell__right-icon{
        color:#FBD3A3;
    }
    .van-cell:after{
       border: 0!important;
    }
    .van-collapse-item--border:after{
      border:0!important;
    }
}
</style>