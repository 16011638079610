import { createRouter, createWebHistory,createWebHashHistory} from 'vue-router'

const routes = [
  // {
  //   path: '/',
  //   redirect: '/home',
  // },
 
  
  {
    path: '/',
    name: 'Index',
    redirect: '/home',
    component: () => import(/* webpackChunkName: "home" */ './pages/index/index.vue'),
    children:[
      {
        path: '/home',
        component: () => import('@/pages/index/home.vue'),
        name: 'Home',
      },{
        path: '/settlement',
        name: 'Settlement',
        meta:{
          KeepAlive:false
        },
        component: () =>
          import('@/pages/index/settlement.vue'),
      },{
        path: '/my',
        name: 'My',
        meta:{
          KeepAlive:false
        },
        component: () =>
          import('@/pages/my/my.vue'),
      }
    ]
  },{
    path:"/news",
    name: 'News',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/news.vue'),
  }
  ,{
    path: '/chatroom',
    name: 'ChatRoom',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/chatroom/index.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import('@/pages/login/login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/login/register.vue'),
  },
  {
    path: '/forgot',
    name: 'Forgot',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/login/forgot.vue'),
  },
  {
    path: '/bill',
    name: 'Bill',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/bill.vue'),
  },
  {
    path: '/enroll',
    name: 'Enroll',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/enroll.vue'),
  },
  {
    path: '/enrolldetail',
    name: 'Enrolldetail',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/index/enrolldetail.vue'),
  }, {
    path: '/info',
    name: 'Info',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/info.vue'),
  },
  {
    path: '/yqlink',
    name: 'Yqlink',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/yqlink.vue'),
  },{
    path:'/editPws',
    name: 'EditPws',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/editPws.vue'),
  },{
    path:'/addBank',
    name: 'AddBank',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/addBank.vue'),
  },{
    path:'/addrList',
    name: 'AddrList',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/addrList.vue'),
  },{
    path:'/order',
    name: 'Order',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/order.vue'),
  },{
    path:'/orderDetail',
    name: 'OrderDetail',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/orderDetail.vue'),
  },{
    path:'/addAddr',
    name: 'AddAddr',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/addAddr.vue'),
  },{
    path:'/applyCard',
    name: 'ApplyCard',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/applyCard.vue'),
  },{
    path:'/apply',
    name: 'Apply',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/apply.vue'),
  },{
    path:'/sign',
    name: 'Sign',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/sign.vue'),
  },{
    path:'/pay',
    name: 'Pay',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/pay.vue'),
  },{
    path:'/buyJf',
    name: 'BuyJf',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/buyJf.vue'),
  },{
    path:'/vip',
    name: 'Vip',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/vip.vue'),
  },{
    path:'/payMoney',
    name: 'PayMoney',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/payMoney.vue'),
  },{
    path:"/applydetail",
    name: 'Applydetail',
    meta:{
      KeepAlive:false
    },
    component: () =>
      import('@/pages/my/applydetail.vue'),
  }
]

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

// 0-9，a-z, length:8
const randomString = () => {
  return Math.random().toString(36).slice(-8)
}

router.beforeEach((to) => {
  
})

router.afterEach(() => {
  // 隐藏菜单
  // hideMenuItems()
})
