
const baseUrl = location.protocol+'//'+window.location.host ;
import axios from 'axios'
import { getToken, removeToken} from './auth'
import {router} from '@/router'
import qs from 'qs'
import { showLoadingToast,closeToast,showToast } from 'vant';
// import { JSONStringify } from 'lib/tool';
// import Vue from 'vue'
import { store } from '@/store'

const service = axios.create({
  baseURL: baseUrl,
  // withCredentials:true,
  timeout: 60000,
  headers:{
    'Content-Type':'application/json; charset=utf-8'
  }
  
})

service.defaults.headers.post['Content-Type']='application/x-www-form-urlencoded;charset=utf-8'

const pending = []
const CancelToken = axios.CancelToken
const removePending = (req) => {
  for (const p in pending) {
      let str = ''
      if(req.params){
        str = JSON.stringify(req.params)
      }
    if (pending[p].u === req.url + '&' + req.method +'&'+ str) {
      pending[p].f()
      pending.splice(p, 1)
    }
  }
}


service.interceptors.request.use(
  (req) => { 
    if(req.method=='post'){
      // req.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      // req.data = qs.stringify(req.data)
    }
    if(req.method=='get'){
      req.headers['Content-Type'] = 'application/json; charset=utf-8'
    }
    //  添加固定参数
    let token = getToken() || ''
    if(token) req.headers['Token'] =  token
    req.params = {
      ...req.params,
      // token:getToken() || '',
      // Language:getLang() || 'en'
    }
    removePending(req)
    req.cancelToken = new CancelToken((c) => {
      let str = ''
      if(req.params){
        str = JSON.stringify(req.params)
      }
      pending.push({ u: req.url + '&' + req.method +'&'+ str, f: c })
    })
    return req
  },
  (error) => {
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  (response) => {
    closeToast()
    removePending(response.config)
   
      if (response.data.ErrorCode === 0) {
        // 请求 success
        localStorage.setItem('login',true)
        return Promise.resolve(response.data) 
      } else {
        if (
          response.data.ErrorCode === -2
        ) {
          if(response.data.RedirectUrl){
            // location.href = response.data.RedirectUrl;
            // return
          }
          //未登录
          removeToken()
          
          showToast('请登录')
          localStorage.setItem('login',false)
          // store.dispatch('getClear')
          setTimeout(()=>{
            router.push("/login")
          },1500)
          return
        } 
        // setIsPast(true)
        showToast(response.data.ErrorMsg)
        
        return Promise.reject(new Error(response.data.ErrorMsg || 'Error'))
      }
   
  },
  (error) => {
    console.log(error)
    // closeToast()
    if(error.code === 'ECONNABORTED' || error == 'timeout of 60000ms exceeded'){  
      showToast('系统繁忙，请稍后重试')
      
    }

    return Promise.reject(error)
  }
)
export default service
