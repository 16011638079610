import request from '../utils/request'
import { showLoadingToast,closeToast,showToast } from 'vant';


//  初始化接口
export function ValidateCode(data) {
  return request({
    url: '/Qt/common/GetValidateCode',
    method: 'post',
    data,
  })
}

export function CarouselImage(params) {
  return request({
    url: '/Qt/DefaultF/GetCarouselImage',
    method: 'get',
    params,
  })
}

export function Regedit(params) {
  return request({
    url: '/Qt/DefaultF/Regedit',
    method: 'get',
    params,
  })
}

export function GetJumpLinkUrl(params) {
  return request({
    url: '/Qt/DefaultF/GetJumpLinkUrl',
    method: 'get',
    params,
  })
}

export function GetRoomToken(params) {
  return request({
    url: '/Qt/MemberF/GetRoomToken',
    method: 'get',
    params,
  })
}

export function GetRoomUserList(params) {
  return request({
    url: '/Qt/MemberF/GetRoomUserList',
    method: 'get',
    params,
  })
}

export function AllGagRoom(data) {
  showLoadingToast({
      duration:0,
      message:'设置中' +'...',
      forbidClick: true,
    });
  return request({
    url: '/Qt/MemberF/AllGagRoom',
    method: 'post',
    data,
  })
}

export function CancelAllGagRoom(data) {
  showLoadingToast({
      duration:0,
      message:'设置中' +'...',
      forbidClick: true,
    });
  return request({
    url: '/Qt/MemberF/CancelAllGagRoom',
    method: 'post',
    data,
  })
}

export function GagRoom(data) {
  showLoadingToast({
      duration:0,
      message:'禁言中' +'...',
      forbidClick: true,
    });
  return request({
    url: '/Qt/MemberF/GagRoom',
    method: 'post',
    data,
  })
}

export function postUserList(data) {
  return request({
    url: '/Qt/MemberF/UserList',
    method: 'post',
    data,
  })
}

export function GetFUserList(params) {
  return request({
    url: '/Qt/MemberF/GetUserList',
    method: 'get',
    params,
  })
}

export function CancelGagRoom(data) {
  showLoadingToast({
      duration:0,
      message:'取消中' +'...',
      forbidClick: true,
    });
  return request({
    url: '/Qt/MemberF/CancelGagRoom',
    method: 'post',
    data,
  })
}


export function GetAllGagRoom(params) {
  return request({
    url: '/Qt/MemberF/GetAllGagRoom',
    method: 'get',
    params,
  })
}

export function PopupNews(params) {
  return request({
    url: '/Qt/MemberF/PopupNews',
    method: 'get',
    params,
  })
}



export function GetRoomID(params) {
  return request({
    url: '/Qt/MemberF/GetRoomID',
    method: 'get',
    params,
  })
}

export function GetApiUrl(params) {
  return request({
    url: '/Qt/MemberF/GetApiUrl',
    method: 'get',
    params,
  })
}

export function getCuctionStock(params) {
  return request({
    url: '/Qt/StockF/CuctionStock',
    method: 'get',
    params,
  })
}

export function Login(data) {
  showLoadingToast({
      duration:0,
      message:'登录中' +'...',
      forbidClick: true,
    });
  return request({
    url: '/Qt/DefaultF/Login',
    method: 'post',
    data,
  })
}


export function RegeditSumbit(data) {
  showLoadingToast({
    duration:0,
    message:'提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/DefaultF/RegeditSumbit',
    method: 'post',
    data,
  })
}

export function ForgetPasswordForTel(data) {
  return request({
    url: '/Qt/DefaultF/ForgetPasswordForTel',
    method: 'post',
    data,
  })
}

export function RegisterCode(data) {
  return request({
    url: '/Qt/DefaultF/RegisterCode',
    method: 'post',
    data,
  })
}

export function ForgetPasswordCode(data) {
  return request({
    url: '/Qt/DefaultF/ForgetPasswordCode',
    method: 'post',
    data,
  })
}

export function EditMemberLoginPwd(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/EditMemberLoginPwd',
    method: 'post',
    data,
  })
}

export function EditMemberSecondPwd(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/EditMemberSecondPwd',
    method: 'post',
    data,
  })
}

export function NewNews(params) {
  return request({
    url: '/Qt/DefaultF/NewNews',
    method: 'get',
    params,
  })
}
export function GetProject(params) {
  return request({
    url: '/Qt/ProjectF/GetProject',
    method: 'get',
    params,
  })
}


export function NewsInfo(params) {
  return request({
    url: '/Qt/DefaultF/GetNewsInfo',
    method: 'get',
    params,
  })
}

export function GetTradeMarketList(params) {
  return request({
    url: '/Qt/DefaultF/GetTradeMarketList',
    method: 'get',
    params,
  })
}

export function GetNoticeImage(params) {
  return request({
    url: '/Qt/DefaultF/GetNoticeImage',
    method: 'get',
    params,
  })
}

export function GetNewsList(params) {
  return request({
    url: '/Qt/NoticeF/GetNewsList',
    method: 'get',
    params,
  })
}

export function GetNewsDetail(params) {
  return request({
    url: '/Qt/NoticeF/GetNewsDetail',
    method: 'get',
    params,
  })
}

export function SignIn(params) {
  return request({
    url: '/Qt/MemberF/GetSignIn',
    method: 'get',
    params,
  })
}

export function GetProjectApply(params) {
  return request({
    url: '/Qt/ProjectF/GetProjectApply',
    method: 'get',
    params,
  })
}

export function SubmitProjectApply(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/ProjectF/SubmitProjectApply',
    method: 'post',
    data,
  })
}

export function SignInSubmit(data) {
  showLoadingToast({
    duration:0,
    message: '签到中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/SignIn',
    method: 'post',
    data,
  })
}

export function MyTeamList(params) {
  return request({
    url: '/Qt/MemberF/MyTeamList',
    method: 'get',
    params,
  })
}

export function GetBillflowList(params) {
  return request({
    url: '/Qt/FinanceF/GetBillflowList',
    method: 'get',
    params,
  })
}


export function EditMemberView(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/EditMemberView',
    method: 'post',
    data,
  })
}

export function LoginPwdCode(data) {
  showLoadingToast({
    duration:0,
    message: '发送中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/MemberLoginPwdCode',
    method: 'post',
    data,
  })
}

export function SecondPwdCode(data) {
  showLoadingToast({
    duration:0,
    message: '发送中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/MemberSecondPwdCode',
    method: 'post',
    data,
  })
}

export function getMemberView(params) {
  return request({
    url: '/Qt/MemberF/EditMemberView',
    method: 'get',
    params,
  })
}

export function MemberView(params) {
  return request({
    url: '/Qt/MemberF/MemberView',
    method: 'get',
    params,
  })
}

export function BankListUrl(data) {
  return request({
    url: '/Qt/common/GetBankList',
    method: 'post',
    data,
  })
}

export function EditBankView(params) {
  return request({
    url: '/Qt/MemberF/EditBankView',
    method: 'get',
    params,
  })
}



export function MyBankList(params) {
  return request({
    url: '/Qt/MemberF/GetMyBankList',
    method: 'get',
    params,
  })
}


export function MyBankEdit(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/EditBankView',
    method: 'post',
    data,
  })
}

export function DelBank(data) {
  showLoadingToast({
    duration:0,
    message: '删除中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt//MemberF/DelBank',
    method: 'post',
    data,
  })
}

export function uploadimg(data) {
  showLoadingToast({
    duration:0,
    message: '上传中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/common/uploadimg',
    method: 'post',
    data,
  })
}

export function Activeted(params) {
  return request({
    url: '/Qt/MemberF/Activeted',
    method: 'get',
    params,
  })
}

export function PayActiveted(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/PayActiveted',
    method: 'post',
    data,
  })
}


export function NoReadNotice(params) {
  return request({
    url: '/Qt/MemberF/GetMemberNoReadNotice',
    method: 'get',
    params,
  })
}

export function ReferralLink(params) {
  return request({
    url: '/Qt/MemberF/GetReferralLink',
    method: 'get',
    params,
  })
}


export function AwardsRecord(params) {
  return request({
    url: '/Qt/FinanceF/GainAwardsRecord',
    method: 'get',
    params,
  })
}

export function InMailList(params) {
  return request({
    url: '/Qt/NoticeF/GetInMailList',
    method: 'get',
    params,
  })
}

export function GetMsgDetail(params) {
  return request({
    url: '/Qt/NoticeF/GetMsgDetail',
    method: 'get',
    params,
  })
}


export function SendLetter(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/NoticeF/SendLetter',
    method: 'post',
    data,
  })
}

export function uploadimgBase64(data) {
  return request({
    url: '/Qt/common/uploadimgBase64',
    method: 'post',
    data,
  })
}

export function MyInvestRecordList(params) {
  return request({
    url: '/Qt/InvestF/GetMyInvestRecordList',
    method: 'get',  
    params,
  })
}

export function getInvest(params) {
  return request({
    url: '/Qt/InvestF/InvestSubmit',
    method: 'get',
    params,
  })
}



export function ReplySubmit(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/InvestF/ReplySubmit',
    method: 'post',
    data,
  })
}


export function getSubmitWithdraw(params) {
  return request({
    url: '/Qt/FinanceF/SubmitWithdraw',
    method: 'get',
    params,
  })
}

export function SubmitWithdraw(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/FinanceF/SubmitWithdraw',
    method: 'post',
    data,
  })
}

export function SendWithdrawCode(data) {
  return request({
    url: '/Qt/FinanceF/SendWithdrawCode',
    method: 'post',
    data,
  })
}

export function Rechargeinfo(params) {
  return request({
    url: '/Qt/FinanceF/GetRechargeOnlineConfig',
    method: 'get',
    params,
  })
}

export function RechargeOnlineSubmit(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/FinanceF/RechargeOnlineSubmit',
    method: 'post',
    data,
  })
}

export function GetRemittances(params) {
  return request({
    url: '/Qt/FinanceF/GetRemittances',
    method: 'get',
    params,
  })
}

export function GetWithdraws(params) {
  return request({
    url: '/Qt/FinanceF/GetWithdraws',
    method: 'get',
    params,
  })
}

export function MyRebateRecordList(params) {
  return request({
    url: '/Qt/InvestF/GetMyRebateRecordList',
    method: 'get',
    params,
  })
}

export function Loginout(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/Loginout',
    method: 'post',
    data,
  })
}


export function GetAppLink(params) {
  return request({
    url: '/Qt/DefaultF/GetAppLinkUrl',
    method: 'get',
    params,
  })
}

export function StockListUrl(params) {
  return request({
    url: '/Qt/StockF/GetStockList',
    method: 'get',
    params,
  })
}

export function MyStock(params) {
  return request({
    url: '/Qt/StockF/GetMyStock',
    method: 'get',
    params,
  })
}

export function EquityCertificate(params) {
  return request({
    url: '/Qt/StockF/GetEquityCertificate',
    method: 'get',
    params,
  })
}

export function toExStock(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/StockF/ExStock',
    method: 'post',
    data,
  })
}

export function BuyStock(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/StockF/BuyStock',
    method: 'post',
    data,
  })
}

export function MyStockRecord(params) {
  return request({
    url: '/Qt/StockF/GetMyStockRecord',
    method: 'get',
    params,
  })
}



export function GetEPPrice(params) {
  return request({
    url: '/Qt/EPTradeF/GetEPPrice',
    method: 'get',
    params,
  })
}

export function getCuctionList(params) {
  return request({
    url: '/Qt/StockF/GetCuctionList',
    method: 'get',
    params,
  })
}

export function CuctionStock(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/StockF/CuctionStock',
    method: 'post',
    data,
  })
}

export function GetCuctionInfo(params) {
  return request({
    url: '/Qt/StockF/GetCuctionInfo',
    method: 'get',
    params,
  })
}

export function GetCuctionBuyList(params) {
  return request({
    url: '/Qt/StockF/GetCuctionBuyList',
    method: 'get',
    params,
  })
}

export function GetReservation(params) {
  return request({
    url: '/Qt/StockF/GetReservation',
    method: 'get',
    params,
  })
}

export function Reservation(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/StockF/Reservation',
    method: 'post',
    data,
  })
}

export function JoinAuction(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/StockF/JoinAuction',
    method: 'post',
    data,
  })
}

export function MyCuctionList(params) {
  return request({
    url: '/Qt/StockF/GetMyCuctionList',
    method: 'get',
    params,
  })
}

export function ExStock(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/StockF/ExStock',
    method: 'post',
    data,
  })
}


export function BlindBoxConfig(params) {
  return request({
    url: '/Qt/BlindBoxF/GetBlindBoxConfig',
    method: 'get',
    params,
  })
}


export function GetBlindBoxList(params) {
  return request({
    url: '/Qt/BlindBoxF/GetBlindBoxList',
    method: 'get',
    params,
  })
}

export function BuyBlindBox(data) {
  // showLoadingToast({
  //   duration:0,
  //   message: '提交中...',
  //   forbidClick: true,
  // });
  return request({
    url: '/Qt/BlindBoxF/BuyBlindBox',
    method: 'post',
    data,
  })
}


export function MyBlindBoxList(params) {
  return request({
    url: '/Qt/BlindBoxF/GetMyBlindBoxList',
    method: 'get',
    params,
  })
}


export function GetCertification(params) {
  return request({
    url: '/Qt/MemberF/GetCertification',
    method: 'get',
    params,
  })
}


export function SendCertificationCode(data) {
  showLoadingToast({
    duration:0,
    message: '发送中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/SendCertificationCode',
    method: 'post',
    data,
  })
}

export function SubmitCertification(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MemberF/SubmitCertification',
    method: 'post',
    data,
  })
}


export function GetBankInfo(params) {
  return request({
    url: '/Qt/BankF/GetBankInfo',
    method: 'get',
    params,
  })
}

export function GetBankApplyList(params) {
  return request({
    url: '/Qt/BankF/GetBankApplyList',
    method: 'get',
    params,
  })
}

export function GetProjectInfo(params) {
  return request({
    url: '/Qt/ProjectF/GetProjectInfo',
    method: 'get',
    params,
  })
}

export function ConfirmGoods(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/BankF/ConfirmGoods',
    method: 'post',
    data,
  })
}

export function PayBankApply(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/BankF/PayBankApply',
    method: 'post',
    data,
  })
}

export function GetBankPay(params) {
  return request({
    url: '/Qt/BankF/GetPay',
    method: 'get',
    params,
  })
}

export function SubmitBankApply(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/BankF/SubmitBankApply',
    method: 'post',
    data,
  })
}


export function SubmitWithdraw2(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/FinanceF/SubmitWithdraw2',
    method: 'post',
    data,
  })
}


export function GetBankApply(params) {
  return request({
    url: '/Qt/BankF/GetBankApply',
    method: 'get',
    params,
  })
}

export function PayBailFee(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/ProjectF/PayBailFee',
    method: 'post',
    data,
  })
}

export function PayWithdrawFee(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/ProjectF/PayWithdrawFee',
    method: 'post',
    data,
  })
}

export function WithdrawApply(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/ProjectF/WithdrawApply',
    method: 'post',
    data,
  })
}

export function PaySettleProject(data) {
  showLoadingToast({
    duration:0,
    message: '提交中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/ProjectF/PaySettleProject',
    method: 'post',
    data,
  })
}

export function GetPay(params) {
  return request({
    url: '/Qt/ProjectF/GetPay',
    method: 'get',
    params,
  })
}

export function GetMallAddrList(params) {
  return request({
    url: '/Qt/MallF/GetMallAddrList',
    method: 'get',
    params,
  })
}


export function GetMallAddr(params) {
  return request({
    url: '/Qt/MallF/GetMallAddr',
    method: 'get',
    params,
  })
}

export function GetDefaultAddr(params) {
  return request({
    url: '/Qt/MallF/GetDefaultAddr',
    method: 'get',
    params,
  })
}

export function GetMyProjectList(params) {
  return request({
    url: '/Qt/ProjectF/GetMyProjectList',
    method: 'get',
    params,
  })
}


export function GeAppLinkUrl(params) {
  return request({
    url: '/Qt/DefaultF/GeAppLinkUrl',
    method: 'get',
    params,
  })
}

export function GeDownLoadUrl(params) {
  return request({
    url: '/Qt/DefaultF/GeDownLoadUrl',
    method: 'get',
    params,
  })
}

export function SetDefaultMallAddr(data) {
  showLoadingToast({
    duration:0,
    message: '设置中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MallF/SetDefaultMallAddr',
    method: 'post',
    data,
  })
}

export function DelMallAddr(data) {
  showLoadingToast({
    duration:0,
    message: '删除中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MallF/DelMallAddr',
    method: 'post',
    data,
  })
}

export function SaveMallAddr(data) {
  showLoadingToast({
    duration:0,
    message: '保存中...',
    forbidClick: true,
  });
  return request({
    url: '/Qt/MallF/SaveMallAddr',
    method: 'post',
    data,
  })
}